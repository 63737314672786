import styled, { css } from 'styled-components';
import { DefaultTheme } from 'styled-components';

type TypographyVariant = keyof DefaultTheme['typography'];
type TextColor = 'primary' | 'secondary' | 'accent' | 'white';

interface TextProps {
  $variant?: Exclude<TypographyVariant, 'fontFamily'>;
  $color?: TextColor;
  $align?: 'left' | 'center' | 'right';
  $noMargin?: boolean;
}

const getColorFromProp = (color: TextColor = 'primary', theme: DefaultTheme) => {
  if (!theme?.colors) return 'inherit';
  
  switch (color) {
    case 'primary':
      return theme.colors.text.primary;
    case 'secondary':
      return theme.colors.text.secondary;
    case 'accent':
      return theme.colors.accent;
    case 'white':
      return theme.colors.white;
    default:
      return theme.colors.text.primary;
  }
};

const getVariantStyles = (variant: Exclude<TypographyVariant, 'fontFamily'> = 'body1', theme: DefaultTheme) => {
  if (!theme?.typography || !theme.typography[variant]) {
    console.warn(`Typography variant "${variant}" not found in theme`);
    return css`
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
    `;
  }

  const styles = theme.typography[variant];
  return css`
    font-size: ${styles.fontSize};
    font-weight: ${styles.fontWeight};
    line-height: ${styles.lineHeight};
  `;
};

export const Text = styled.p<TextProps>`
  ${({ theme, $variant }) => getVariantStyles($variant, theme)}
  color: ${({ theme, $color }) => getColorFromProp($color, theme)};
  text-align: ${({ $align }) => $align || 'left'};
  margin: ${({ $noMargin }) => $noMargin ? '0' : '0 0 1rem 0'};
`;
