import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Container, Text, Grid } from '../common';
import { theme } from '../../styles/theme';

const FooterWrapper = styled.footer`
  background-color: ${theme.colors.surface};
  padding: ${theme.spacing.xxl} 0;
  margin-top: auto;
`;

const FooterLink = styled(Link)`
  color: ${({ theme }) => theme.colors.text.secondary};
  text-decoration: none;
  transition: color ${({ theme }) => theme.transitions.fast};

  &:hover {
    color: ${theme.colors.primary};
  }
`;

const FooterSection = styled.div`
  @media (max-width: ${theme.breakpoints.mobile}) {
    text-align: center;
  }
`;

const FooterGrid = styled(Grid)`
  @media (max-width: ${theme.breakpoints.tablet}) {
    gap: ${theme.spacing.xl};
  }
`;

export const Footer: React.FC = () => {
  return (
    <FooterWrapper>
      <Container>
        <FooterGrid $columns={{ desktop: 4, tablet: 2, mobile: 1 }} $gap="xl">
          <FooterSection>
            <Text $variant="h4" $color="primary" $noMargin>
              Flippose
            </Text>
            <Text $color="secondary">
              Monitor and improve your posture with real-time feedback
            </Text>
          </FooterSection>

          <FooterSection>
            <Text $variant="h4" $color="primary" $noMargin>
              Product
            </Text>
            <ul>
              <li>
                <FooterLink to="/features">Features</FooterLink>
              </li>
              <li>
                <FooterLink to="/download">Download</FooterLink>
              </li>
              <li>
                <FooterLink to="/upgrade">Pricing</FooterLink>
              </li>
            </ul>
          </FooterSection>

          <FooterSection>
            <Text $variant="h4" $color="primary" $noMargin>
              Support
            </Text>
            <ul>
              <li>
                <FooterLink to="/faq">FAQ</FooterLink>
              </li>
              <li>
                <FooterLink to="/contact">Contact</FooterLink>
              </li>
              <li>
                <FooterLink to="/help">Help Center</FooterLink>
              </li>
            </ul>
          </FooterSection>

          <FooterSection>
            <Text $variant="h4" $color="primary" $noMargin>
              Legal
            </Text>
            <ul>
              <li>
                <FooterLink to="/terms">Terms of Service</FooterLink>
              </li>
              <li>
                <FooterLink to="/privacy">Privacy Policy</FooterLink>
              </li>
            </ul>
          </FooterSection>
        </FooterGrid>

        <Text
          $color="secondary"
          $align="center"
          $noMargin
          style={{ marginTop: theme.spacing.xl }}
        >
          {new Date().getFullYear()} Flippose. All rights reserved.
        </Text>
      </Container>
    </FooterWrapper>
  );
};
