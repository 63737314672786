import styled, { css, DefaultTheme } from 'styled-components';

interface CardProps {
  variant?: 'default' | 'elevated' | 'outlined';
  padding?: keyof DefaultTheme['spacing'];
  interactive?: boolean;
}

const getVariantStyles = (variant: CardProps['variant'] = 'default', theme: DefaultTheme) => {
  switch (variant) {
    case 'elevated':
      return css`
        box-shadow: ${theme.shadows.md};
      `;
    case 'outlined':
      return css`
        border: 1px solid ${theme.colors.border};
      `;
    default:
      return css`
        background: ${theme.colors.surface};
      `;
  }
};

export const Card = styled.div<CardProps>`
  ${({ theme, variant }) => getVariantStyles(variant, theme)}
  padding: ${({ theme, padding = 'lg' }) => theme.spacing[padding]};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background: ${({ theme }) => theme.colors.surface};
  transition: box-shadow ${({ theme }) => theme.transitions.fast};

  ${({ interactive, variant, theme }) => interactive && css`
    cursor: pointer;
    &:hover {
      transform: translateY(-2px);
      box-shadow: ${variant === 'elevated' ? theme.shadows.lg : theme.shadows.md};
    }
  `}
`;
