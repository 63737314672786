import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Layout } from './components/layout';
import { GlobalStyles } from './styles/GlobalStyles';
import { theme } from './styles/theme';
import { UpgradeProvider } from './contexts/UpgradeContext';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import PasswordProtection from './components/auth/PasswordProtection';

// Lazy load pages
const Home = React.lazy(() => import('./pages/Home'));
const Features = React.lazy(() => import('./pages/Features'));
const Upgrade = React.lazy(() => import('./pages/Upgrade'));
const Download = React.lazy(() => import('./pages/Download'));
const Account = React.lazy(() => import('./pages/Account'));
const Terms = React.lazy(() => import('./pages/Terms'));
const Privacy = React.lazy(() => import('./pages/Privacy'));
const SignIn = React.lazy(() => import('./pages/SignIn'));
const VerifySignIn = React.lazy(() => import('./pages/VerifySignIn'));
const SignUp = React.lazy(() => import('./pages/SignUp'));
const Payment = React.lazy(() => import('./pages/Payment'));
const SubscriptionSuccess = React.lazy(() => import('./pages/SubscriptionSuccess'));
const ManageSubscription = React.lazy(() => import('./pages/ManageSubscription'));
const UpdatePayment = React.lazy(() => import('./pages/UpdatePayment'));
const ReactivateSubscription = React.lazy(() => import('./pages/ReactivateSubscription'));
const VerifyPhone = React.lazy(() => import('./pages/VerifyPhone'));

// RequireAuth component to protect routes
const RequireAuth: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { currentUser, loading } = useAuth();
  const location = useLocation();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!currentUser) {
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};

function App() {
  return (
    <PasswordProtection>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <AuthProvider>
          <Router>
            <UpgradeProvider>
              <Layout>
                <React.Suspense fallback={<div>Loading...</div>}>
                  <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/home" element={<Home />} />
                    <Route path="/features" element={<Features />} />
                    <Route path="/upgrade" element={<Upgrade />} />
                    <Route path="/download" element={<Download />} />
                    <Route path="/terms" element={<Terms />} />
                    <Route path="/privacy" element={<Privacy />} />
                    <Route path="/signin" element={<SignIn />} />
                    <Route path="/verify-signin" element={<VerifySignIn />} />
                    <Route path="/signup" element={<SignUp />} />
                    
                    {/* Protected Routes */}
                    <Route 
                      path="/account" 
                      element={
                        <RequireAuth>
                          <Account />
                        </RequireAuth>
                      } 
                    />
                    <Route 
                      path="/payment" 
                      element={
                        <RequireAuth>
                          <Payment plan="monthly" />
                        </RequireAuth>
                      } 
                    />
                    <Route 
                      path="/subscription-success" 
                      element={
                        <RequireAuth>
                          <SubscriptionSuccess />
                        </RequireAuth>
                      } 
                    />
                    <Route 
                      path="/manage-subscription" 
                      element={
                        <RequireAuth>
                          <ManageSubscription />
                        </RequireAuth>
                      } 
                    />
                    <Route 
                      path="/reactivate-subscription" 
                      element={
                        <RequireAuth>
                          <ReactivateSubscription />
                        </RequireAuth>
                      } 
                    />
                    <Route 
                      path="/update-payment" 
                      element={
                        <RequireAuth>
                          <UpdatePayment />
                        </RequireAuth>
                      } 
                    />
                    <Route path="/verify-phone" element={<VerifyPhone />} />
                  </Routes>
                </React.Suspense>
              </Layout>
            </UpgradeProvider>
          </Router>
        </AuthProvider>
      </ThemeProvider>
    </PasswordProtection>
  );
}

export default App;
