import styled, { css, DefaultTheme } from 'styled-components';

export type ButtonVariant = 'primary' | 'secondary' | 'outline';
export type ButtonSize = 'small' | 'medium' | 'large';

interface ButtonProps {
  variant?: ButtonVariant;
  size?: ButtonSize;
  fullWidth?: boolean;
  disabled?: boolean;
  icon?: React.ReactNode;
}

const getVariantStyles = (variant: ButtonVariant = 'primary', theme: DefaultTheme) => {
  switch (variant) {
    case 'primary':
      return css`
        background: ${theme.colors.primary};
        color: ${theme.colors.white};
        border: none;

        &:hover {
          background: ${theme.colors.secondary};
        }
      `;
    case 'secondary':
      return css`
        background: ${theme.colors.secondary};
        color: ${theme.colors.white};
        border: none;

        &:hover {
          opacity: 0.9;
        }
      `;
    case 'outline':
      return css`
        background: transparent;
        color: ${theme.colors.primary};
        border: 2px solid ${theme.colors.primary};

        &:hover {
          background: ${theme.colors.primary};
          color: ${theme.colors.white};
        }
      `;
    default:
      return '';
  }
};

const getSizeStyles = (size: ButtonSize = 'medium', theme: DefaultTheme) => {
  switch (size) {
    case 'small':
      return css`
        padding: ${theme.spacing.xs} ${theme.spacing.sm};
        font-size: 0.875rem;
      `;
    case 'large':
      return css`
        padding: ${theme.spacing.md} ${theme.spacing.lg};
        font-size: 1.125rem;
      `;
    default:
      return css`
        padding: ${theme.spacing.sm} ${theme.spacing.md};
        font-size: 1rem;
      `;
  }
};

export const Button = styled.button<ButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.borderRadius.md};
  font-weight: ${({ theme }) => theme.typography.button.fontWeight};
  transition: all ${({ theme }) => theme.transitions.fast};
  width: ${({ fullWidth }) => fullWidth ? '100%' : 'auto'};
  border: none;
  cursor: pointer;
  text-decoration: none;
  gap: ${({ theme }) => theme.spacing.sm};

  ${({ variant, theme }) => getVariantStyles(variant, theme)}
  ${({ size, theme }) => getSizeStyles(size, theme)}

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:focus-visible {
    outline: 2px solid ${({ theme }) => theme.colors.primary};
    outline-offset: 2px;
  }
`;
