import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faUser } from '@fortawesome/free-solid-svg-icons';
import { Container, Flex, Button } from '../common';
import { theme } from '../../styles/theme';
import { useAuth } from '../../contexts/AuthContext';

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: ${theme.colors.background}ee;
  backdrop-filter: blur(10px);
  padding: ${theme.spacing.md} 0;
  z-index: 1000;
  border-bottom: 1px solid ${theme.colors.border};
`;

const Logo = styled(Link)`
  font-size: 1.5rem;
  font-weight: 700;
  color: ${theme.colors.text};
  text-decoration: none;
  background: linear-gradient(135deg, ${theme.colors.primary}, ${theme.colors.secondary});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const MenuButton = styled.button`
  display: none;
  color: ${theme.colors.text};
  font-size: 1.5rem;
  background: none;
  border: none;
  cursor: pointer;
  padding: ${theme.spacing.sm};

  @media (max-width: ${theme.breakpoints.tablet}) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const NavLinks = styled.div<{ $isOpen: boolean }>`
  display: flex;
  gap: ${theme.spacing.lg};
  align-items: center;

  @media (max-width: ${theme.breakpoints.tablet}) {
    display: ${props => props.$isOpen ? 'flex' : 'none'};
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    background-color: ${theme.colors.background};
    padding: ${theme.spacing.lg};
    border-bottom: 1px solid ${theme.colors.border};
  }
`;

const NavLink = styled(Link)<{ $active?: boolean }>`
  color: ${props => props.$active ? theme.colors.primary : theme.colors.text};
  text-decoration: none;
  font-weight: 500;
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  border-radius: ${theme.borderRadius.md};
  transition: all ${theme.transitions.fast};
  border: 1px solid ${theme.colors.border};

  &:hover {
    color: ${theme.colors.primary};
    background: ${theme.colors.primary}11;
    border-color: ${theme.colors.primary};
  }

  &[href="/signin"] {
    background: transparent;
    border: 1px solid ${theme.colors.border};
    
    &:hover {
      border-color: ${theme.colors.primary};
      background: ${theme.colors.primary}11;
    }
  }
`;

export const Navbar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const { currentUser, signOut: authSignOut } = useAuth();
  const [showDropdown, setShowDropdown] = useState(false);

  const isActive = (path: string) => location.pathname === path;

  const handleSignOut = async () => {
    try {
      await authSignOut();
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <Nav>
      <Container>
        <Flex $justify="between" $align="center">
          <Logo to="/">Flippose</Logo>
          <MenuButton onClick={() => setIsOpen(!isOpen)}>
            <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
          </MenuButton>
          <NavLinks $isOpen={isOpen}>
            {currentUser ? (
              <>
                <NavLink to="/account" $active={isActive('/account')}>
                  Account
                </NavLink>
                <Button onClick={handleSignOut} variant="secondary">
                  Sign Out
                </Button>
              </>
            ) : (
              <>
                <NavLink to="/signin" $active={isActive('/signin')}>
                  Sign In
                </NavLink>
                <Button as={Link} to="/signup">
                  Sign Up
                </Button>
              </>
            )}
          </NavLinks>
        </Flex>
      </Container>
    </Nav>
  );
};
