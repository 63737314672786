import React, { createContext, useContext, useState, ReactNode } from 'react';

interface UpgradeContextType {
  isUpgrading: boolean;
  setUpgrading: (upgrading: boolean) => void;
}

const defaultContext: UpgradeContextType = {
  isUpgrading: false,
  setUpgrading: () => {},
};

const UpgradeContext = createContext<UpgradeContextType>(defaultContext);

export const useUpgrade = () => {
  const context = useContext(UpgradeContext);
  if (!context) {
    throw new Error('useUpgrade must be used within an UpgradeProvider');
  }
  return context;
};

export const UpgradeProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [isUpgrading, setUpgrading] = useState(false);

  const value: UpgradeContextType = {
    isUpgrading,
    setUpgrading,
  };

  return (
    <UpgradeContext.Provider value={value}>
      {children}
    </UpgradeContext.Provider>
  );
};

export default UpgradeContext;
