import styled, { css } from 'styled-components';
import { theme } from '../../styles/theme';

interface GridProps {
  $columns?: number | { mobile?: number; tablet?: number; desktop?: number };
  $gap?: keyof typeof theme.spacing;
  $alignItems?: 'start' | 'center' | 'end' | 'stretch';
  $justifyItems?: 'start' | 'center' | 'end' | 'stretch';
}

export const Grid = styled.div<GridProps>`
  display: grid;
  gap: ${props => theme.spacing[props.$gap || 'lg']};
  align-items: ${props => props.$alignItems || 'stretch'};
  justify-items: ${props => props.$justifyItems || 'stretch'};

  ${props => {
    if (typeof props.$columns === 'number') {
      return css`
        grid-template-columns: repeat(${props.$columns}, 1fr);

        @media (max-width: ${theme.breakpoints.tablet}) {
          grid-template-columns: repeat(${Math.min(props.$columns, 2)}, 1fr);
        }

        @media (max-width: ${theme.breakpoints.mobile}) {
          grid-template-columns: 1fr;
        }
      `;
    }

    if (props.$columns) {
      return css`
        grid-template-columns: repeat(${props.$columns.desktop || 3}, 1fr);

        @media (max-width: ${theme.breakpoints.tablet}) {
          grid-template-columns: repeat(${props.$columns.tablet || 2}, 1fr);
        }

        @media (max-width: ${theme.breakpoints.mobile}) {
          grid-template-columns: repeat(${props.$columns.mobile || 1}, 1fr);
        }
      `;
    }

    return css`
      grid-template-columns: repeat(3, 1fr);

      @media (max-width: ${theme.breakpoints.tablet}) {
        grid-template-columns: repeat(2, 1fr);
      }

      @media (max-width: ${theme.breakpoints.mobile}) {
        grid-template-columns: 1fr;
      }
    `;
  }}
`;
