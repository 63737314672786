import styled from 'styled-components';

interface ContainerProps {
  fluid?: boolean;
  noPadding?: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  margin: 0 auto;
  max-width: ${({ fluid }) => fluid ? '100%' : '1200px'};
  padding: ${({ noPadding, theme }) => noPadding ? '0' : `0 ${theme.spacing.lg}`};

  @media (max-width: ${({ theme }) => theme.breakpoints.tablet}) {
    padding: ${({ noPadding, theme }) => noPadding ? '0' : `0 ${theme.spacing.md}`};
  }
`;
